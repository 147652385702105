import React from 'react'
import classNames from 'classnames'
import useDarkMode from '../../../hooks/useDarkMode'
import Footer from '../../../layout/Footer/Footer'
import { APP_VERSION } from '../../../config/constants'

const DefaultFooter = () => {
	const { darkModeStatus } = useDarkMode()
	const year = new Date().getFullYear();
	return (
		<Footer>
			<div className="container-fluid">
				<div className="row">
					<div className="col">
						<span className="fw-light">Copyright © {year} - TRANSPORTERS Version {APP_VERSION}</span>
					</div>
					<div className="col-auto">
						<a
							href="/"
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}
						>
							<small className="fw-bold">Created by <a href="https://webtitans.gr">WEBTITANS</a></small>
						</a>
					</div>
				</div>
			</div>
		</Footer>
	)
}

export default DefaultFooter
