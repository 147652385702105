import React, { Suspense } from 'react'
import { useContext } from 'react'
import Card, { CardBody } from '../../components/bootstrap/Card'
import PageWrapper from '../PageWrapper/PageWrapper'
import Page from '../Page/Page'
import SubHeader from '../SubHeader/SubHeader'
import ContentRoutes from './ContentRoutes'
import { SocketProvider } from '../../contexts/socketContext'
import ThemeContext from '../../contexts/themeContext'

const LOADING = (
	<PageWrapper>
		<SubHeader>
			<div />
		</SubHeader>
		<Page>
			<div className="row h-100">
				<div className="col-lg-6">
					<Card stretch>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
				<div className="col-lg-6">
					<Card stretch="semi">
						<CardBody>
							<div />
						</CardBody>
					</Card>
					<Card stretch="semi">
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
			</div>
		</Page>
	</PageWrapper>
)

const Content = () => {
	const { mobileDesign } = useContext(ThemeContext)

	return (
		<main className="content" style={{ marginTop: mobileDesign ? '5rem' : '' }}>
			<Suspense fallback={LOADING}>
				<SocketProvider>
					<ContentRoutes />
				</SocketProvider>
			</Suspense>
		</main>
	)
}

export default Content
